//! avoid 'import * as' pattern when possible
import * as services from '../services/serviceSystemAlert';
import * as userServices from '../services/serviceUser';

import { fetchError, fetchStart, fetchSuccess } from './common/actionFetch';

export const GET_SYSTEM_ALERTS_TYPES = 'GET_SYSTEM_ALERTS_TYPES';
export const getSystemAlertsTypes = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_SYSTEM_ALERTS_TYPES));
    services
      .getSystemAlertTypes()
      .then((response) => {
        dispatch({
          type: GET_SYSTEM_ALERTS_TYPES,
          payload: response.data.rows,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_SYSTEM_ALERTS_TYPES)))
      .catch(() => dispatch(fetchError(GET_SYSTEM_ALERTS_TYPES)));
  };
};

export const GET_VESSELS = 'GET_VESSELS';
export const getVessels = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSELS));
    services
      .getVessels()
      .then((response) => {
        dispatch({
          type: GET_VESSELS,
          payload: response.data.rows,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_VESSELS)))
      .catch(() => dispatch(fetchError(GET_VESSELS)));
  };
};

export const CREATE_NEW_SYSTEM_ALERT = 'CREATE_NEW_SYSTEM_ALERT';
export const createNewSystemAlert = (data, cb) => {
  return (dispatch) => {
    dispatch(fetchStart(CREATE_NEW_SYSTEM_ALERT));
    services
      .createNewSystemAlert(data)
      .then((response) => {
        cb(null, response);
      })
      .then(() =>
        dispatch(() => {
          fetchSuccess(CREATE_NEW_SYSTEM_ALERT);
        })
      )
      .catch((err) => {
        cb(err, null);
        dispatch(fetchError(CREATE_NEW_SYSTEM_ALERT));
      });
  };
};

export const GET_SYSTEM_ALERTS = 'GET_SYSTEM_ALERTS';
export const getSystemAlerts = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_SYSTEM_ALERTS));
    services
      .getSystemAlerts(data)
      .then((response) => {
        dispatch({
          type: GET_SYSTEM_ALERTS,
          payload: response.data,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_SYSTEM_ALERTS)))
      .catch(() => dispatch(fetchError(GET_SYSTEM_ALERTS)));
  };
};

export const UPDATE_SYSTEM_ALERT = 'UPDATE_SYSTEM_ALERT';
export const updateSystemAlert = (data, cb) => {
  return (dispatch) => {
    dispatch(fetchStart(UPDATE_SYSTEM_ALERT));
    services
      .updateSystemAlert(data)
      .then((response) => {
        cb(null, response);
      })
      .then(() =>
        dispatch(() => {
          fetchSuccess(UPDATE_SYSTEM_ALERT);
        })
      )
      .catch((err) => {
        cb(err, null);
        dispatch(fetchError(UPDATE_SYSTEM_ALERT));
      });
  };
};

export const DELETE_SYSTEM_ALERT = 'DELETE_SYSTEM_ALERT';
export const deleteSystemAlert = (data, cb) => {
  return (dispatch) => {
    dispatch(fetchStart(DELETE_SYSTEM_ALERT));
    services
      .deleteSystemAlert(data)
      .then((response) => {
        cb(null, response);
      })
      .then(() =>
        dispatch(() => {
          fetchSuccess(DELETE_SYSTEM_ALERT);
        })
      )
      .catch((err) => {
        cb(err, null);
        dispatch(fetchError(DELETE_SYSTEM_ALERT));
      });
  };
};

export const GET_EMAIL_OPTIONS = 'GET_EMAIL_OPTIONS';
export const getEmailOptions = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_EMAIL_OPTIONS));
    userServices
      .getUsers({
        all: true,
        activeOnly: true,
      })
      .then((response) => {
        dispatch({
          type: GET_EMAIL_OPTIONS,
          payload: response.data.usersList.map((u) => u?.user_email),
        });
      })
      .then(() => dispatch(fetchSuccess(GET_EMAIL_OPTIONS)))
      .catch(() => dispatch(fetchError(GET_EMAIL_OPTIONS)));
  };
};
