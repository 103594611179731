import * as fuelCappingActions from '../actions/actionFuelCapping';
import {
  defaultDateRange,
  EXTERNAL_SUPPLY_RUN_TYPE,
  FUEL_CAPPING_SCHEDULE_TYPE,
  numManualScheduleStartingRows,
  OFFSHORE_EMPTY_CRITICAL,
  OFFSHORE_EMPTY_DP_MODE,
  OFFSHORE_OPS_FO_TYPE,
  Sequence,
} from '../constants/fms';
import { generateRandomId, getNumber, isNumber } from '../services/helper';
import { cloneDeep, isEmpty, isNil, meanBy, sum, sumBy } from 'lodash';

export const defaultState = {
  fuelCappingPage: {
    headerId: null,
    vesselStandardRate: null,
    fuelTransactionId: null,
    fuelRequestId: null,
    meterTicketId: null,
    transactionStatus: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    vesselInformation: {
      regionCode: null,
      vesselId: null,
      scheduleType: null,
      scheduleBunkerDateRange: defaultDateRange,
      unloadedES: null,
      estimatedRob: null,
      bunkerAmountRequested: null,
      estimatedBunkerDate: defaultDateRange,
      remarks: null,
      vesselRob: null,
      showRemarks: false,
    },
    enrouteSection: {
      pltVoyageId: null,
      voyageDataFull: null,
      voyageDataSelected: [],
      manualScheduleData: {
        voyageData: Array.from(Array(numManualScheduleStartingRows), (_, idx) => new Sequence(idx + 1)),
        accumulatedDistance: 0,
        avgSpeed: 0,
        avgBenchmark: 0,
        accumulatedInterField: 0,
      },
      standbyVessel: null,
      bunkerAt: null,
      grandTotal: {
        accumulatedDistance: 0,
        avgSpeed: 0,
        avgBenchmark: 0,
        accumulatedInterField: 0,
      },
    },
    offshoreSection: {
      offshore: {
        foConsumptionType: OFFSHORE_OPS_FO_TYPE.STANDARD,
        operationDays: null,
        dailyAverageBenchmark: null,
        totalFo: 0,
      },
      critical: {
        operationDays: null,
        dailyAverageBenchmark: OFFSHORE_EMPTY_CRITICAL,
        totalFo: 0,
      },
      dpmode: {
        operationDays: null,
        dailyAverageBenchmark: OFFSHORE_EMPTY_DP_MODE,
        totalFo: 0,
      },
      accumulatedTotalFo: 0,
      defaultStandardOperationDays: 0,
      totalOperationDays: 0,
    },
    bufferSection: {
      buffer: 0,
      total: 0,
    },
    externalSupplyRunSection: {
      total: 0,
      supplyToPlatform: 0,
      supplyToRig: 0,
      awb: 0,
      gpv: 0,
      fcb: 0,
      ahts: 0,
      other: [
        { id: generateRandomId(), title: null, value: null },
        { id: generateRandomId(), title: null, value: null },
      ],
    },
    summarySection: {
      usuableRob: 0,
      estUsage: 0,
      totalBuffer: 0,
      externalSupplyRun: 0,
      totalDemand: 0,
      cappedAmount: 0,
      estRobAfterBunker: 0,
      approvedBunkerAmount: 0,
    },
  },
};

const REDUCERS = {
  [fuelCappingActions.GET_FUEL_CAPPING_SECTIONS_DATA]: (state, { payload }) => { //NOSONAR
    if (!isEmpty(payload)) {
      let tempGrandTotalData = cloneDeep(defaultState.fuelCappingPage.enrouteSection.grandTotal);
      const isHeaderIdExist = !isNil(payload.header_id);

      if (isHeaderIdExist) {
        //header_id exist => historical data
        const enrouteData = payload.enroute_section ?? [];
        tempGrandTotalData.accumulatedDistance = sumBy(enrouteData, 'accumulatedDistance');
        tempGrandTotalData.accumulatedInterField = sumBy(enrouteData, 'accumulatedInterField');
        tempGrandTotalData.avgBenchmark = meanBy(enrouteData, 'avgBenchmark');
        tempGrandTotalData.avgSpeed = meanBy(enrouteData, 'avgSpeed');
      }

      let tempOffshoreSection = cloneDeep(defaultState.fuelCappingPage.offshoreSection);
      if (isNil(payload.offshore_operation_section)) {
        tempOffshoreSection = {
          ...tempOffshoreSection,
          offshore: {
            ...tempOffshoreSection.offshore,
            dailyAverageBenchmark: getNumber(payload.vessel_standard_rate.daily_fo_consumption_standard_rate),
          },
          critical: {
            ...tempOffshoreSection.critical,
            dailyAverageBenchmark: isNumber(payload.vessel_standard_rate.critical_ops_standard_rate)
              ? getNumber(payload.vessel_standard_rate.critical_ops_standard_rate)
              : payload.vessel_standard_rate.critical_ops_standard_rate,
          },
          dpmode: {
            ...tempOffshoreSection.dpmode,
            dailyAverageBenchmark: isNumber(payload.vessel_standard_rate.dp_mode_daily_fo_consumption_standard_rate)
              ? getNumber(payload.vessel_standard_rate.dp_mode_daily_fo_consumption_standard_rate)
              : payload.vessel_standard_rate.dp_mode_daily_fo_consumption_standard_rate,
          },
        };
      } else {
        tempOffshoreSection = {
          ...tempOffshoreSection,
          ...payload.offshore_operation_section,
          accumulatedTotalFo: sumBy(Object.values(payload.offshore_operation_section), 'totalFo'),
          totalOperationDays: sumBy(Object.values(payload.offshore_operation_section), 'operationDays'),
        };
      }
      let resultStandbyVessel = defaultState.fuelCappingPage.enrouteSection.standbyVessel;
      if (!isNil(payload.vessel_information)) {
        resultStandbyVessel = payload.vessel_information?.standbyVessel ? 1 : 0;
      }
      return {
        ...state,
        fuelCappingPage: {
          ...state.fuelCappingPage,
          headerId: payload.header_id,
          vesselStandardRate: payload.vessel_standard_rate,
          fuelTransactionId: payload.fuel_transaction_id,
          fuelRequestId: payload.fuel_request_id,
          meterTicketId: payload.meter_ticket_id,
          transactionStatus: payload.transaction_status ?? null,
          enrouteSection: {
            ...state.fuelCappingPage.enrouteSection,
            pltVoyageId: payload.plt_voyage_id || undefined,
            voyageDataFull: payload.enroute_section ?? [],
            voyageDataSelected: isHeaderIdExist ? payload.enroute_section : [],
            manualScheduleData:
              payload?.vessel_information?.scheduleType === FUEL_CAPPING_SCHEDULE_TYPE.MANUAL
                ? payload.enroute_section[0]
                : defaultState.fuelCappingPage.enrouteSection.manualScheduleData,
            grandTotal: tempGrandTotalData,
            bunkerAt: payload.vessel_information?.bunkerAt ?? defaultState.fuelCappingPage.enrouteSection.bunkerAt,
            standbyVessel: resultStandbyVessel,
          },
          offshoreSection: tempOffshoreSection,
          bufferSection: payload.buffer_section ?? defaultState.fuelCappingPage.bufferSection,
          externalSupplyRunSection:
            payload.external_supply_run ?? defaultState.fuelCappingPage.externalSupplyRunSection,
          summarySection: payload.summary ?? defaultState.fuelCappingPage.summarySection,
          vesselInformation: {
            ...state.fuelCappingPage.vesselInformation,
            regionCode: payload.vessel_information?.regionCode ?? state.fuelCappingPage.vesselInformation.regionCode,
            vesselId: payload.vessel_information?.vesselId ?? state.fuelCappingPage.vesselInformation.vesselId,
            scheduleType:
              payload.vessel_information?.scheduleType ?? state.fuelCappingPage.vesselInformation.scheduleType,
            scheduleBunkerDateRange:
              payload.vessel_information?.scheduleBunkerDateRange ??
              state.fuelCappingPage.vesselInformation.scheduleBunkerDateRange,
            unloadedES: payload.vessel_information?.unloadedES ?? state.fuelCappingPage.vesselInformation.unloadedES,
            estimatedRob:
              payload.vessel_information?.estimatedRob ?? state.fuelCappingPage.vesselInformation.estimatedRob,
            bunkerAmountRequested:
              payload.vessel_information?.bunkerAmountRequested ??
              state.fuelCappingPage.vesselInformation.bunkerAmountRequested,
            estimatedBunkerDate:
              payload.vessel_information?.estimatedBunkerDate ??
              state.fuelCappingPage.vesselInformation.estimatedBunkerDate,
            remarks: payload.vessel_information?.remarks,
          },
          createdBy: payload.createdBy?.display_name ?? null,
          updatedBy: payload.updatedBy?.display_name ?? null,
          createdDate: payload.createdDate,
          updatedDate: payload.updatedDate,
        },
      };
    } else {
      return {
        ...state,
        fuelCappingPage: {
          ...state.fuelCappingPage,
          enrouteSection: {
            ...state.fuelCappingPage.enrouteSection,
            voyageDataFull: [],
          },
        },
      };
    }
  },
  [fuelCappingActions.FUEL_CAPPING_SELECT_VOYAGES]: (state, { payload }) => {
    let tempGrandTotalData = cloneDeep(defaultState.fuelCappingPage.enrouteSection.grandTotal);
    let tempOffshoreOperationData = cloneDeep(state.fuelCappingPage.offshoreSection);
    let tempDefaultOperationDays = 0;
    const isHeaderIdExist = !isNil(state.fuelCappingPage.headerId);
    const selectedVoyagesData = state.fuelCappingPage.enrouteSection.voyageDataFull?.filter((item) =>
      payload.includes(item.tripId)
    );
    if (!isEmpty(selectedVoyagesData)) {
      tempGrandTotalData = {
        accumulatedDistance: sumBy(selectedVoyagesData, 'accumulatedDistance'),
        accumulatedInterField: sumBy(selectedVoyagesData, 'accumulatedInterField'),
        avgBenchmark: meanBy(selectedVoyagesData, 'avgBenchmark'),
        avgSpeed: meanBy(selectedVoyagesData, 'avgSpeed'),
      };
    }

    const formattedSelectedVoyagesData = selectedVoyagesData?.map((item, idx) => { //NOSONAR      
      //Calculate Offshore Operation data
      if (!isHeaderIdExist) {
        const firstTrip = item.voyageData[0];
        const vesselStandardRateAvailable = !isNil(state.fuelCappingPage.vesselStandardRate);
        const tempOffshoreData = tempOffshoreOperationData.offshore;
        const tempCriticalData = tempOffshoreOperationData.critical;
        const tempDpmodeData = tempOffshoreOperationData.dpmode;
        tempDefaultOperationDays += firstTrip.cycleTime;
        const offshoreOpsDays = vesselStandardRateAvailable ? tempOffshoreData.operationDays + firstTrip.cycleTime : 0;

        const offshoreBenchmark =
          tempOffshoreData.dailyAverageBenchmark +
          getNumber(state.fuelCappingPage.vesselStandardRate.daily_fo_consumption_standard_rate);

        let criticalOpsDays = 0;
        if (vesselStandardRateAvailable && isNumber(tempCriticalData.dailyAverageBenchmark)) {
          criticalOpsDays = offshoreOpsDays === 0 ? null: offshoreOpsDays
        }

        const criticalBenchmark = !isNumber(tempCriticalData.dailyAverageBenchmark)
          ? tempCriticalData.dailyAverageBenchmark
          : getNumber(tempCriticalData.dailyAverageBenchmark) +
            getNumber(state.fuelCappingPage.vesselStandardRate.critical_ops_standard_rate);

        let dpmodeOpsDays = 0;
        if (vesselStandardRateAvailable && isNumber(tempDpmodeData.dailyAverageBenchmark)) {
          dpmodeOpsDays = offshoreOpsDays === 0 ? null: offshoreOpsDays
        }

        const dpmodeBenchmark = !isNumber(tempDpmodeData.dailyAverageBenchmark)
          ? tempDpmodeData.dailyAverageBenchmark
          : getNumber(tempDpmodeData.dailyAverageBenchmark) +
            getNumber(state.fuelCappingPage.vesselStandardRate.dp_mode_daily_fo_consumption_standard_rate);

        tempOffshoreOperationData = {
          offshore: {
            ...tempOffshoreData,
            foConsumptionType: tempOffshoreData.foConsumptionType,
            operationDays: offshoreOpsDays,
            dailyAverageBenchmark: offshoreBenchmark,
          },
          critical: {
            ...tempCriticalData,
            operationDays: criticalOpsDays,
            dailyAverageBenchmark: criticalBenchmark,
          },
          dpmode: {
            ...tempDpmodeData,
            operationDays: dpmodeOpsDays,
            dailyAverageBenchmark: dpmodeBenchmark,
          },
        };
      } else {
        tempOffshoreOperationData = state.fuelCappingPage.offshore_operation_section;
      }
      return item;
    });

    return {
      ...state,
      fuelCappingPage: {
        ...state.fuelCappingPage,
        enrouteSection: {
          ...state.fuelCappingPage.enrouteSection,
          voyageDataSelected: formattedSelectedVoyagesData,
          grandTotal: tempGrandTotalData,
        },
        offshoreSection: { ...tempOffshoreOperationData, defaultStandardOperationDays: tempDefaultOperationDays },
      },
    };
  },
  [fuelCappingActions.GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_DATA]: (state, { payload }) => {
    if (payload.length > 0) {
      const { SUPPLY_TO_PLATFORM, SUPPLY_TO_RIG, AWB, GPV, FCB, AHTS } = EXTERNAL_SUPPLY_RUN_TYPE;
      return {
        ...state,
        fuelCappingPage: {
          ...state.fuelCappingPage,
          externalSupplyRunSection: {
            supplyToPlatform: payload.find((item) => item.typeName === SUPPLY_TO_PLATFORM.label)?.value ?? 0,
            supplyToRig: payload.find((item) => item.typeName === SUPPLY_TO_RIG.label)?.value ?? 0,
            awb: payload.find((item) => item.typeName === AWB.label)?.value ?? 0,
            gpv: payload.find((item) => item.typeName === GPV.label)?.value ?? 0,
            fcb: payload.find((item) => item.typeName === FCB.label)?.value ?? 0,
            ahts: payload.find((item) => item.typeName === AHTS.label)?.value ?? 0,
            total:
              sum(
                payload.map((item) => {
                  if (
                    [
                      SUPPLY_TO_PLATFORM.label,
                      SUPPLY_TO_RIG.label,
                      AWB.label,
                      FCB.label,
                      GPV.label,
                      AHTS.label,
                    ].includes(item.typeName)
                  )
                    return item.value;
                })
              ) ?? 0,
          },
        },
      };
    }
  },
  [fuelCappingActions.GET_FUEL_CAPPING_SUMMARY_LIST]: (state, { payload }) => {
    return {
      ...state,
      fuelCappingPage: {
        ...state.fuelCappingPage,
        summary_list: payload,
      },
    };
  },
  [fuelCappingActions.SET_STATE_FUELCAPPING_PAGE]: (state, { payload }) => {
    const { keys = [], value } = payload;
    const newState = { ...state.fuelCappingPage };
    let currentLevel = newState;
    keys.forEach((key, index) => {
      if (index !== keys.length - 1) {
        currentLevel = currentLevel[key];
      } else {
        currentLevel[key] = value;
      }
    });

    return { ...state, fuelCappingPage: { ...state.fuelCappingPage, ...newState } };
  },
  [fuelCappingActions.RESET_STATE_FUELCAPPING_PAGE]: (state) => {
    return {
      ...state,
      fuelCappingPage: {
        ...state.fuelCappingPage,
        ...defaultState.fuelCappingPage,
      },
    };
  },
  [fuelCappingActions.GET_VESSEL_ROB]: (state, { payload }) => {
    return {
      ...state,
      fuelCappingPage: {
        ...state.fuelCappingPage,
        vesselInformation: {
          ...state.fuelCappingPage.vesselInformation,
          vesselRob: payload,
        },
      },
    };
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, { type, payload, error }) => { //NOSONAR
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
};

export const FUEL_CAPPING_PAGE_REDUCER = 'fuelCappingPageReducer';
