import * as Msal from "@azure/msal-browser" 
import * as Axios from "axios";
import _ from "lodash";
import { appInsights } from "./appInsights";
import TagManager from 'react-gtm-module'

const applicationConfig = (config) => {
  return {
    auth: {
      validateAuthority: true,
      clientId: process.env.REACT_APP_AZURE_CLIENTID,
      authority: "https://login.microsoftonline.com/" + process.env.REACT_APP_AZURE_TENANT + "/",
      redirectUri: process.env.REACT_APP_URI_REDIRECT,
      postLogoutredirectUri: process.env.REACT_APP_URI_REDIRECT,
      navigateToLoginRequestUrl: false,
      scopes: ["User.Read"],
    },
    cache: {
      cacheLocation: process.env.REACT_APP_CACHE_LOCATION,
      // storeAuthStateInCookie: true,
    },
  };
};

const authenticationParameters = {
  scopes: ["api://" + process.env.REACT_APP_API_ID + "/OLV.ReadAll"]
};

let _instance;
export class AuthService {
  constructor(config) {
    try {
      if (_instance) {
        this.instance = _instance; 
      } else {
        _instance = new Msal.PublicClientApplication(applicationConfig(config));
        this.instance = _instance;
        AuthService.init(this.instance);
      }
    } catch (e) {
      localStorage.clear();
      sessionStorage.clear();
      appInsights.clearAuthenticatedUserContext();
    }
  }

  static async init(instance) {
    instance.initialize();
  }

  async getInstance() {
    return this.instance;
  }

  async handleRedirectCallback(response) {
    if (response) {
      const user = await _instance.getAccount();
      localStorage.setItem("user", JSON.stringify(user));
      window.location.href = localStorage.getItem("urlHit");
    } else {
      localStorage.setItem("error", "login error");
      
      await _instance.logout();
      localStorage.clear();
      sessionStorage.clear();
      appInsights.clearAuthenticatedUserContext();
    }
  };

  login = async () => {
    return await _instance.loginRedirect(authenticationParameters);
  };

  account = async () => {
    const accounts = await _instance?.getAllAccounts();
    return accounts?.[0];
  };

  error = async () => {
    let error = localStorage.getItem("error");
    return error;
  };

  logout = async () => {
    await _instance.logout();
    localStorage.clear();
    sessionStorage.clear();
    appInsights.clearAuthenticatedUserContext();
  };

  async getToken(account, isRefresh = false) {
    try {
      let response = await _instance.acquireTokenSilent({
        ...authenticationParameters,
        forceRefresh: isRefresh,
        account
      });

      if (response) {
        const isAlreadySignedIn = JSON.parse(localStorage.getItem("alreadySignedIn"));
        //console.log("######",JSON.parse(localStorage.getItem("alreadySignedIn")))
        const uid = response?.idToken?.objectId ;
        if(!isAlreadySignedIn) {
          const tagManagerArg = {
              dataLayer: {
              event: 'Login',
              uid: uid ,
              uid_event: uid,
              dim1: uid
            }
          }
        
        TagManager.dataLayer(tagManagerArg);
        localStorage.setItem("alreadySignedIn", true);

        }
        localStorage.setItem("authResponse", JSON.stringify(response));
      }
      if (response.idToken != null || response.idToken != undefined) {
        localStorage.setItem("idToken", response.idToken.rawIdToken);
      };

      if (response.accessToken != null) {
        localStorage.setItem("accessToken", response.accessToken);
      };

      return response;

    } catch (error) {
      console.log(error?.errorCode);
      console.log(error?.message);

      // if (error?.errorCode === "consent_required" || error?.errorCode === "interaction_required" || error?.errorCode === "login_required") {
      //   try {
      //     let response = await _instance.acquireTokenPopup(authenticationParameters);
      //     return response;
      //   } catch (error) {
      //     return await _instance.loginRedirect(authenticationParameters);
      //   }
      // };

      // return await _instance.loginRedirect(authenticationParameters);
      return null;
    }
  };

  isAuthenticated = () => {
    return this.getToken().then((response) => {
      if (response && response.accessToken) {
        return response;
      }
      return null;
    });
  };

  getUserAccess = () => {
    return new Promise((resolve, reject) => {
      const authResponse = JSON.parse(localStorage.getItem("authResponse") || "{}");
      Axios({
        method: "get",
        url: process.env.REACT_APP_API_ENDPOINTS + "saas/userAccessData",
        headers: {
          Authorization: "Bearer " + authResponse?.accessToken,
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUBSCRIPTION_KEY,
        },
      }).then(function (response) {
        let userAccessResponse = _.get(response.data, 'data');

        let moduleList = _.get(userAccessResponse, 'module_list')
        let accessModule = _.map(moduleList, (item) => item.module_name);
        let roleList = _.get(userAccessResponse, "roles");
        let regionList = _.get(userAccessResponse, "regions");
        let locationList = _.get(userAccessResponse, "user_locations");
        let linkedDiviions = _.get(userAccessResponse, "linked_divisions");

        let userAccessProperties = {
          email: _.get(userAccessResponse, "email"),
          role: _.map(roleList, (item) => item.role_name),
          company: _.get(userAccessResponse, "division_name"),
          region: _.map(regionList, (item) => item.region_name),
          location: _.map(locationList, (item) => item.location_name),
          linkedDivisions: _.map(linkedDiviions, (item) => item.division_id),
        };

        localStorage.setItem("accessModule", _.reject(accessModule, _.isEmpty));
        localStorage.setItem("userAccessProperties", JSON.stringify(userAccessProperties));

        resolve(response);
      }).catch((error) => {
        console.log('UserAccess Api Error Response, ', error)
        reject(error)
      })
    });
  };
}

export default AuthService;
