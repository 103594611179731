import { fetchError, fetchStart, fetchSuccess } from './common/actionFetch';
import * as services from '../services/serviceFuelCapping';
import moment from 'moment';

export const GET_FUEL_CAPPING_SECTIONS_DATA = 'GET_FUEL_CAPPING_SECTIONS_DATA';
export const getFuelCappingSectionsData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_FUEL_CAPPING_SECTIONS_DATA));
    services
      .getFuelCappingSectionsData(data)
      .then((response) => {
        dispatch({
          type: GET_FUEL_CAPPING_SECTIONS_DATA,
          payload: response.result,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_FUEL_CAPPING_SECTIONS_DATA)));
    // .catch(() => dispatch(fetchError(GET_FUEL_CAPPING_SECTIONS_DATA)));
  };
};

export const FUEL_CAPPING_SELECT_VOYAGES = 'FUEL_CAPPING_SELECT_VOYAGES';
export const selectVoyagesFuelCappingSectionData = (voyages) => {
  return (dispatch) => {
    dispatch({
      type: FUEL_CAPPING_SELECT_VOYAGES,
      payload: voyages,
    });
  };
};

export const GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_DATA = 'GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_DATA';
export const getExternalSupplyRunData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_DATA));
    services
      .getExternalSupplyRunData(data)
      .then((response) => {
        if (response?.is_valid) {
          dispatch({
            type: GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_DATA,
            payload: response?.result,
          });
        }
      })
      .then(() => dispatch(fetchSuccess(GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_DATA)))
      .catch(() => dispatch(fetchError(GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_DATA)));
  };
};

export const CALCULATE_FUEL_CAPPING_SUMMARY = 'CALCULATE_FUEL_CAPPING_SUMMARY';
export const calculateFuelCappingSummary = (data, cb) => {
  return (dispatch) => {
    dispatch(fetchStart(CALCULATE_FUEL_CAPPING_SUMMARY));
    services
      .calculateFuelCappingSummary(data)
      .then((response) => {
        dispatch({
          type: CALCULATE_FUEL_CAPPING_SUMMARY,
          payload: response?.result,
        });
        if (response) cb?.(null, response);
      })
      .then(() => dispatch(fetchSuccess(CALCULATE_FUEL_CAPPING_SUMMARY)))
      .catch((err) => {
        cb?.(err, null);
        dispatch(fetchError(CALCULATE_FUEL_CAPPING_SUMMARY));
      });
  };
};

export const GET_FUEL_CAPPING_SUMMARY_LIST = 'GET_FUEL_CAPPING_SUMMARY_LIST';
export const getFuelCappingSummaryList = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_FUEL_CAPPING_SUMMARY_LIST));
    services
      .getFuelCappingSummaryList(data)
      .then((response) => {
        dispatch({
          type: GET_FUEL_CAPPING_SUMMARY_LIST,
          payload: response.data,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_FUEL_CAPPING_SUMMARY_LIST)))
      .catch((err) => {
        dispatch(fetchError(GET_FUEL_CAPPING_SUMMARY_LIST));
      });
  };
};

export const SET_STATE_FUELCAPPING_PAGE = 'SET_STATE_FUELCAPPING_PAGE';
export const setStateFuelCappingPage = (keys, value) => {
  return (dispatch) => {
    dispatch({
      type: SET_STATE_FUELCAPPING_PAGE,
      payload: { keys, value },
    });
  };
};

export const RESET_STATE_FUELCAPPING_PAGE = 'RESET_STATE_FUELCAPPING_PAGE';
export const resetStateFuelCappingPage = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_STATE_FUELCAPPING_PAGE,
    });
  };
};

export const GET_VESSEL_ROB = 'GET_VESSEL_ROB';
export const getVesselRob = (data) => {
  const yesterday = moment().subtract(1, 'd').format('YYYY-MM-DD');
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_ROB));
    return services
      .getVesselRob({ ...data, date: yesterday })
      .then((response) => {
        dispatch({
          type: GET_VESSEL_ROB,
          payload: response?.result,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_VESSEL_ROB)))
      .catch((error) => {
        dispatch(fetchError(GET_VESSEL_ROB));
        return error.response.data;
      });
  };
};
