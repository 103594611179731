export const EXTERNAL_SUPPLY_RUN_TYPE = Object.freeze({
  SUPPLY_TO_PLATFORM: { label: 'Supply to Platform*', objKey: 'supplyToPlatform' },
  SUPPLY_TO_RIG: {
    label: 'Supply to Rig / JU / Semi Sub / Drill Ship* Naga4',
    objKey: 'supplyToRig',
  },
  AWB: { label: 'AWB', objKey: 'awb' },
  GPV: { label: 'GPV 1', objKey: 'gpv' },
  FCB: { label: 'FCB 1', objKey: 'fcb' },
  AHTS: { label: 'AHTS 1', objKey: 'ahts' },
});

export const PB_PAGENAME = {
  FUEL_CONSUMPTION_CO2_SUMMARY: 'FuelConsumptionCO2Summary',
  DAILY_FUEL_MONITORING: 'DailyFuelMonitoring',
  SPEED_MONITORING: 'SpeedMonitoring',
};

export const VOLUME_FILTER_INPUT_FIELD = {
  ACTION_BY: 'actionBy',
  REGION_ID: 'regionId',
  VESSEL_ID: 'vesselId',
  DATE_RANGE: 'dateRange',
  REQUESTED_DATE: 'requestedDate',
};

export const defaultDateRange = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  },
];

export const FUEL_CAPPING_CALCULATION_STAGE = {
  VESSEL: 'vessel',
  VOYAGES: 'voyages',
  SECTIONS: 'sections',
};

export const FUEL_CAPPING_SCHEDULE_TYPE = {
  EXISTING: 'EXISTING',
  MANUAL: 'MANUAL',
};

export const ENROUTE_MANUAL_ROW_ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove',
  EDIT_REGION: 'region',
  EDIT_FROM_LOCATION: 'from-location',
  EDIT_TO_LOCATION: 'to-location',
  EDIT_STANDBY_VESSEL: 'standby-vessel',
  EDIT_BUNKER_AT: 'bunker-at',
  EDIT_TOWING_ACTIVITY: 'towing-activity',
};

export const VOLUME_TYPE = {
  METER_TICKET: 'Meter Ticket',
  FUEL_REQUEST: 'Fuel Request',
  SOD: 'Statement of Delivery (SOD)',
};

export const BUNKER_AT_TYPE = {
  OFFSHORE: 'Offshore',
  ONSHORE: 'Onshore',
};

export const OFFSHORE_OPS_FO_TYPE = {
  STANDARD: 'standard',
  MANUAL: 'manual',
};

export const OFFSHORE_OPS_TYPE = {
  OFFSHORE: 'Offshore Operation',
  CRITICAL: 'Critical Operation',
  DPMODE: 'Dp Mode',
};

export const OFFSHORE_OPS_KEYS = {
  OFFSHORE: 'offshore',
  CRITICAL: 'critical',
  DPMODE: 'dpmode',
  ACCUMULATED_TOTAL_FO: 'accumulatedTotalFo',
  DAILY_AVERAGE_BENCHMARK: 'dailyAverageBenchmark',
  OPERATION_DAYS: 'operationDays',
  FO_CONSUMPTION_TYPE: 'foConsumptionType',
  TOTAL_FO: 'totalFo',
  TOTAL_OPERATION_DAYS: 'totalOperationDays'
};

export const OFFSHORE_EMPTY_DP_MODE = 'No DP Mode';
export const OFFSHORE_EMPTY_CRITICAL = 'Unable to support Critical Op';

export class Sequence {
  constructor(seq) {
    this.tripId = null;
    this.seq = seq;
    this.origin = null;
    this.destination = null;
    this.speed = 0;
    this.distance = 0;
    this.foConsumption = 0;
    this.interField = 0;
    this.towingActivity = false;
    this.fromRegionId = null;
  }
}

export const numManualScheduleStartingRows = 5;

export const FUEL_CAPPING_ERROR_MESSAGES = {
  region: { required: 'region must be selected' },
  vessel: { required: 'vessel must be selected' },
  scheduleType: { required: 'schedule type must be selected' },
  scheduleBunkerDateRange: { required: 'schedule bunker date range must be selected' },
  unloadedES: { required: 'unloaded external supply must be filled' },
  estRob: { required: 'Estimated ROB must be filled' },
  bunkerAmount: { required: 'Bunker Amount Requested must be filled' },
  estBunkerDate: { required: 'Estimated Bunker Date must be selected' },
  remarks: { required: 'Remarks must be filled' },
  standbyVessel: { required: 'Standby Vessel must be selected' },
  bunkerAt: { required: 'Bunker At must be selected' },
  offshoreOpsDays: {
    required: 'Operation days must be filled',
    overDefaultStandardOpsDays: 'Value must not be larger than default Standard Operation Days!',
    overOffshoreOpsDays: 'Value must be equal or less than operation days of Offshore Operation!',
  },
  buffer: {
    required: 'Emergency buffer must be filled',
  },
};

export const VOLUME_ACTIONS = {
  CREATE_FUEL_CAPPING: 'create-fuel-capping',
  VIEW_FUEL_CAPPING: 'view-fuel-capping',
};

export const estimatedROBFormula = (
  midnightROB = 0,
  vessel_daily_consumption_rate = 0,
  balance_to_bunker_date = 0,
  unload_external_supply = 0
) => midnightROB - vessel_daily_consumption_rate * balance_to_bunker_date - (unload_external_supply ?? 0);

export const TRANSACTION_STATUS = {
  FUEL_REQUEST: 'Fuel Request',
  METER_TICKET: 'Meter Ticket',
  FUEL_CAPPING: 'Fuel Capping',
};
