import React, { useEffect } from "react";
import { Outlet, Route,  Routes   } from "react-router-dom";

// Redux
import * as HeaderTitleActions from "./actions/common/actionHeaderTitle";

// Constants
import { PERMISSIONS } from "./constants/userGroups";

// Helpers
import WithMasterLayout from "./helpers/hoc/withMasterLayout";
import { createNewSocketInstance } from "./helpers/socket.js";
import { useActions, useStateWithPaths } from "./hooks/useConnect.js";
import { HEADER_TITLE_REDUCER } from "./reducers/common/headerTitleReducer.js";

const RouteWithAuth = React.lazy(() => import('./components/route-with-auth/routeWithAuth'));
// General Pages
const UnauthorisedAccessPage = React.lazy(() => import('./containers/views/common/unauthorisedAccessPage'));
const LandingPage = React.lazy(() => import('./containers/views/landing-page'));
const VesselManagementPageNew = React.lazy(() => import('./containers/views/vessel-management-page-new'));
const UserPage = React.lazy(() => import('./containers/views/user-page'));
const MooringBouyManagementPage = React.lazy(() => import('./containers/views/mooring-buoy-management-page'));
const MaintenanceLogPage = React.lazy(() => import('./containers/views/mooring-buoy-maintenance-log'));
const NotificationsPage = React.lazy(() => import('./containers/views/notifications-page'));
const RedirectPage = React.lazy(() => import('./containers/views/common/redirectPage.js'));

// Execute Pages
const MainPage = React.lazy(() => import('./containers/views/main-page'));
const ExplorePage = React.lazy(() => import('./containers/views/explore-page'));
const MainExplorePage = React.lazy(() => import('./containers/views/main-explore-page'));
const KpiOverviewPage = React.lazy(() => import('./containers/views/kpi-overview-page'));
const PlanningPage = React.lazy(() => import('./containers/views/planning-page'));
const SpeedMonitoringPage = React.lazy(() => import('./containers/views/speed-monitoring-page'));
const DeleteUploadedVesselSchedulePage = React.lazy(() => import('./containers/views/delete-uploaded-vessel-schedule-page'));
const DashboardPage = React.lazy(() => import('./containers/views/dashboard'));

// Demand Pages
const BookingPage = React.lazy(() => import('./containers/views/booking-page'));
const BookingListPage = React.lazy(() => import('./containers/views/booking-list-page'));
const FAQPage = React.lazy(() => import('./containers/views/faq-page'));
const SettingsPage = React.lazy(() => import('./containers/views/settings-page'));
const UserGuide = React.lazy(() => import('./containers/views/user-guide-page'));
const CarbonCalculator = React.lazy(() => import('./containers/views/carbon-calculator-page'));
const IAPApprovalPage = React.lazy(() => import('./containers/views/iap-approval-page'));
const ReservationPage = React.lazy(() => import('./containers/views/reservation-page'));
const ReservationViewPage = React.lazy(() => import('./containers/views/reservation-view-page'));
const ReservationIapApprovalPage = React.lazy(() => import('./containers/views/reservation-iap-approval-page'));
const ReservationAllocateVesselPage = React.lazy(() => import('./containers/views/reservation-allocate-vessel'));

// Optimise Pages
const CreateVesselScheduleV2 = React.lazy(() => import('./containers/views/optimise-page/components/create-vessel-schedule/index_v2.js'));
const CreateVesselScheduleV1 = React.lazy(() => import('./containers/views/optimise-page/components/create-vessel-schedule'));
const UploadData = React.lazy(() => import('./containers/views/optimise-page/components/upload-data'));
const ForecastDemand = React.lazy(() => import('./containers/views/optimise-page/components/forecast-demand'));
const SchedulePlanner = React.lazy(() => import('./containers/views/optimise-page/components/schedule-planner'));
const VesselSchedule = React.lazy(() => import('./containers/views/optimise-page/components/vessel-schedule'));

// VOD Pages
const VODSellerVesselList = React.lazy(() => import('./containers/views/vod-page/vod-seller/vessel-list'));
const VODSellerVesselRegistration = React.lazy(() => import('./containers/views/vod-page/vod-seller/vessel-registration'));
const VODBuyerPages = React.lazy(() => import('./containers/views/vod-page/vod-buyer/vessel-reservation'));
const VODBookingPages = React.lazy(() => import('./containers/views/vod-page/vod-booking'));
const VODTrackAndTrace = React.lazy(() => import('./containers/views/vod-page/vod-track-and-trace'));
const VODApprovalRegistration = React.lazy(() => import('./containers/views/vod-page/vod-approval'));
const VODSellerBookings = React.lazy(() => import('./containers/views/vod-page/vod-seller/bookings'));
const VODLocationManagement = React.lazy(() => import('./containers/views/vod-page/location-management/location-list'));
const NotFoundPage = React.lazy(() => import('./containers/views/not-found-page'))

//FMS Pages
const DataManagementPage = React.lazy(() => import('./containers/views/fms/data-management'));
const VolumeDataManagementPage = React.lazy(() => import('./containers/views/fms/data-management/volume'));
const CreateVolumeDataManagementPage = React.lazy(() => import('./containers/views/fms/data-management/volume/createComponent'));
const StandardDailyDataManagementPage = React.lazy(() => import('./containers/views/fms/data-management/standard-daily'));
const SystemAlertPage = React.lazy(() => import('./containers/views/fms/system-alert'));
const FuelCappingPage = React.lazy(() => import('./containers/views/fms/data-management/volume/components/FuelCappingCalculationV2'));
const DashboardSummaryPage = React.lazy(() => import('./containers/views/fms/dashboard-summary'));
const FMSKpiReportPage = React.lazy(()=>import('./containers/views/fms/dashboard-summary/FMSKpiReportPage/FMSKpiReportPage.js'))

const AppRoutes = () => {
  // const { windowSize } = props;
  const [headerTitleData] = useStateWithPaths([`${HEADER_TITLE_REDUCER}`]);

  const {
    setSocketConnection,
  } = useActions({
    setSocketConnection: HeaderTitleActions.setSocketConnection
  });

  const socketConnection = headerTitleData?.socketConnection;

  const execute = "execute";
  const demand = "demand";
  const optimize = "optimize";
  const vod = "vod";
  const fms = "FMS";

  const makeSocketConnection = async () => {
    const newConnection = await createNewSocketInstance();
    setSocketConnection(newConnection);
    console.log(`%cSocket connected.`, `background: #000; color: #FFF; padding: 1px;`);
  }

  useEffect(() => {
    //socket connection
    if (!socketConnection) makeSocketConnection();    
    return () => {
      if (socketConnection) {
        console.log(`%cSocket disconnected.`, `background: #000; color: #FFF; padding: 1px;`)
        socketConnection.disconnect()
      }
    }
  }, [])

  return (
    <>
      <Routes>
        <Route path="/unauthorised-access"  element={<UnauthorisedAccessPage/>} />

        <Route path="/"  element={WithMasterLayout(LandingPage, demand)} />

        <Route path="/notifications"  element={WithMasterLayout(NotificationsPage, demand)} />

        <Route path="/settings"  element={<RouteWithAuth
          component={WithMasterLayout(SettingsPage, demand)}
          module={demand.charAt(0).toUpperCase() + demand.slice(1)}
          forPersona={PERMISSIONS.setting}
        />} />
        {/* // This route is no longer available after integrating the SAAS platform */}
        {/* <Route path="/user"  element={<RouteWithAuth
          component={WithMasterLayout(UserPage, demand)}
          module={demand.charAt(0).toUpperCase() + demand.slice(1)}
          forPersona={PERMISSIONS.setting}
        />}/> */}
        <Route path="/vessel-management"  element={<RouteWithAuth
          component={WithMasterLayout(VesselManagementPageNew, execute)}
          module={execute.charAt(0).toUpperCase() + execute.slice(1)}
          forPersona={PERMISSIONS.vesselManagement}
        />}/>
        {/* ---------------------- Execute ---------------------- Execute ---------------------- Execute ---------------------- Execute ---------------------- Execute ---------------------- */}
        <Route path="/track"  element={<RouteWithAuth
          component={WithMasterLayout(MainPage, execute)}
          module={execute.charAt(0).toUpperCase() + execute.slice(1)}
          forPersona={PERMISSIONS.track}
        />}/>
        <Route path="/explore"  element={<RouteWithAuth
          component={WithMasterLayout(ExplorePage, execute)}
          module={execute.charAt(0).toUpperCase() + execute.slice(1)}
          forPersona={PERMISSIONS.explore}
        />}/>
        <Route path="/main-explore"  element={<RouteWithAuth
          component={WithMasterLayout(MainExplorePage, execute, false)}
          module={execute.charAt(0).toUpperCase() + execute.slice(1)}
          forPersona={PERMISSIONS.mainExplore}
        />}/>
        <Route path="/kpi"  element={<RouteWithAuth
          component={WithMasterLayout(KpiOverviewPage, execute)}
          module={execute.charAt(0).toUpperCase() + execute.slice(1)}
          forPersona={PERMISSIONS.kpi}
        />}/>
        <Route path="/planning"  element={<RouteWithAuth
          component={WithMasterLayout(PlanningPage, execute)}
          module={execute.charAt(0).toUpperCase() + execute.slice(1)}
          forPersona={PERMISSIONS.plainning}
        />}/>
        <Route path="/speed"  element={<RouteWithAuth
          component={WithMasterLayout(SpeedMonitoringPage, execute)}
          module={execute.charAt(0).toUpperCase() + execute.slice(1)}
          forPersona={PERMISSIONS.speed}
        />}/>
        <Route path="/delete-vessel-schedule"  element={<RouteWithAuth
          component={WithMasterLayout(DeleteUploadedVesselSchedulePage, execute)}
          module={execute.charAt(0).toUpperCase() + execute.slice(1)}
          forPersona={PERMISSIONS.deleteVesselSchedule}
        />}/>
        <Route path="/dashboard"  element={<RouteWithAuth
          component={WithMasterLayout(DashboardPage, execute)}
          module={execute.charAt(0).toUpperCase() + execute.slice(1)}
          forPersona={PERMISSIONS.dashboard}
        />}/>
        <Route path="/mooring-buoy-management/:mooring_buoy_name"  element={<RouteWithAuth
          component={WithMasterLayout(MaintenanceLogPage, demand)}
          module={execute.charAt(0).toUpperCase() + execute.slice(1)}
          forPersona={PERMISSIONS.mooringBuoyManagement}
        />}/>
        <Route path="/mooring-buoy-management"  element={<RouteWithAuth
          component={WithMasterLayout(MooringBouyManagementPage, demand)}
          module={execute.charAt(0).toUpperCase() + execute.slice(1)}
          forPersona={PERMISSIONS.mooringBuoyManagement}
        />}/>
        {/* ---------------------- Demand ---------------------- Demand ---------------------- Demand ---------------------- Demand ---------------------- Demand ---------------------- */}
        {/* <RouteWithAuth
          path="/demand"
          component={WithMasterLayout(BookingPage, demand)}
          module={demand.charAt(0).toUpperCase() + demand.slice(1)}
          forPersona={PERMISSIONS.demand}
        />
        <RouteWithAuth
          path="/reservation" // /booking
          component={WithMasterLayout(BookingListPage, demand)}
          module={demand.charAt(0).toUpperCase() + demand.slice(1)}
          forPersona={PERMISSIONS.reservation}
        /> */}
        <Route path="/FAQ"  element={WithMasterLayout(FAQPage, demand)} />
        <Route path="/user-guide"  element={WithMasterLayout(UserGuide, demand)} />
        <Route path="/carbon-calculator"  element={WithMasterLayout(CarbonCalculator, demand)} />
        <Route path="/iap-approval"  element={<RouteWithAuth
          component={WithMasterLayout(IAPApprovalPage, demand)}
          module={demand.charAt(0).toUpperCase() + demand.slice(1)}
          forPersona={PERMISSIONS.iapApproval}
        />}/>
        <Route path="/demand"  element={<RouteWithAuth
          component={WithMasterLayout(ReservationPage, demand)}
          module={demand.charAt(0).toUpperCase() + demand.slice(1)}
          forPersona={PERMISSIONS.demand}
        />}/>
        <Route path="/reservation"  element={<RouteWithAuth
          component={WithMasterLayout(ReservationViewPage, demand)}
          module={demand.charAt(0).toUpperCase() + demand.slice(1)}
          forPersona={PERMISSIONS.reservation}
        />}/>
        {/* <RouteWithAuth
          path="/demand-reservation-iap-approval"
          component={WithMasterLayout(ReservationIapApprovalPage, demand)}
          module={demand.charAt(0).toUpperCase() + demand.slice(1)}
          forPersona={PERMISSIONS.demandTester}
        /> */}
        <Route path="/demand-reservation-allocate-vessel"  element={<RouteWithAuth
          component={WithMasterLayout(ReservationAllocateVesselPage, demand)}
          module={demand.charAt(0).toUpperCase() + demand.slice(1)}
          forPersona={PERMISSIONS.demand}
        />}/>
        

        {/* ---------------------- Optimise ---------------------- Optimise ---------------------- Optimise ---------------------- Optimise ---------------------- Optimise ---------------------- */}
        <Route path="/optimise-create"  element={<RouteWithAuth
          component={WithMasterLayout(CreateVesselScheduleV2, demand)}
          module={optimize.charAt(0).toUpperCase() + optimize.slice(1)}
          forPersona={PERMISSIONS.optimiseCreate}
        />}/>
        <Route path="/optimise-upload"  element={<RouteWithAuth
          component={WithMasterLayout(UploadData, demand)}
          module={optimize.charAt(0).toUpperCase() + optimize.slice(1)}
          forPersona={PERMISSIONS.optimiseUpload}
        />}/>
        <Route path="/optimise-forecast"  element={<RouteWithAuth
          component={WithMasterLayout(ForecastDemand, demand)}
          module={optimize.charAt(0).toUpperCase() + optimize.slice(1)}
          forPersona={PERMISSIONS.optimiseForecast}
        />}/>
        <Route path="/optimise-planner"  element={<RouteWithAuth
          component={WithMasterLayout(SchedulePlanner, demand)}
          module={optimize.charAt(0).toUpperCase() + optimize.slice(1)}
          forPersona={PERMISSIONS.optimisePlanner}
        />}/>
        <Route path="/vessel-schedule"  element={<RouteWithAuth
          component={WithMasterLayout(VesselSchedule, demand)}
          module={optimize.charAt(0).toUpperCase() + optimize.slice(1)}
          forPersona={PERMISSIONS.vesselSchedule}
        />}/>
        {/* ---------------------- FMS ---------------------- FMS ---------------------- FMS ---------------------- FMS ---------------------- FMS ---------------------- */}
        <Route path="/data-management"  element={<RouteWithAuth
          component={WithMasterLayout(DataManagementPage, fms)}
          module={fms.charAt(0).toUpperCase() + fms.slice(1)}
          forPersona={PERMISSIONS.dataManagement}
        />}/>
        <Route path="/data-management/volume"  element={<RouteWithAuth
          component={WithMasterLayout(VolumeDataManagementPage, fms)}
          module={fms.charAt(0).toUpperCase() + fms.slice(1)}
          forPersona={PERMISSIONS.dataManagementVolumeView}
        />}/>
        <Route path="/data-management/volume/view"  element={<RouteWithAuth
          component={WithMasterLayout(CreateVolumeDataManagementPage, fms)}
          module={fms.charAt(0).toUpperCase() + fms.slice(1)}
          forPersona={PERMISSIONS.dataManagementVolumeView}
        />}/>
        <Route path="/data-management/volume/create"  element={<RouteWithAuth
          component={WithMasterLayout(CreateVolumeDataManagementPage, fms)}
          module={fms.charAt(0).toUpperCase() + fms.slice(1)}
          forPersona={PERMISSIONS.dataManagementVolumeCreate}
        />}/>
        <Route path="/data-management/volume/fuel-capping" element={<RouteWithAuth
          component={WithMasterLayout(FuelCappingPage, fms)}
          module={fms.charAt(0).toUpperCase() + fms.slice(1)}
          forPersona={PERMISSIONS.dataManagementFuelCappingView}
        />}/>
        <Route path="/data-management/standard-daily"  element={<RouteWithAuth
          component={WithMasterLayout(StandardDailyDataManagementPage, fms)}
          module={fms.charAt(0).toUpperCase() + fms.slice(1)}
          forPersona={PERMISSIONS.dataManagementStandardDailyView}
        />}/>
        <Route path="/fms-dashboard"  element={<RouteWithAuth
          component={WithMasterLayout(DashboardSummaryPage, fms)}
          module={fms.charAt(0).toUpperCase() + fms.slice(1)}
          forPersona={PERMISSIONS.fmsDashboardView}
        />}/>
        <Route path="/fms-dashboard/kpi-report"  element={<RouteWithAuth
          component={WithMasterLayout(FMSKpiReportPage, fms)}
          module={fms.charAt(0).toUpperCase() + fms.slice(1)}
          forPersona={PERMISSIONS.fmsDashboardView}
        />}/>
        {/* ---------------------- VOD ---------------------- VOD ---------------------- VOD ---------------------- VOD ---------------------- VOD ---------------------- */}
        <Route path="/vod-seller-vessel-list"  element={<RouteWithAuth
          component={WithMasterLayout(VODSellerVesselList, vod)}
          module={vod.charAt(0).toUpperCase()}
          forPersona={PERMISSIONS.vodTester}
        />}/>
        <Route path="/vod-seller-vessel-registration"  element={<RouteWithAuth
          component={WithMasterLayout(VODSellerVesselRegistration, vod)}
          module={vod.charAt(0).toUpperCase()}
          forPersona={PERMISSIONS.vodTester}
        />}/>
        <Route path="/vod-buyer-reservation"  element={<RouteWithAuth
          component={WithMasterLayout(VODBuyerPages, vod)}
          module={vod.charAt(0).toUpperCase()}
          forPersona={PERMISSIONS.vodTester}
        />}/>
        <Route path="/vod-booking"  element={<RouteWithAuth
          component={WithMasterLayout(VODBookingPages, vod)}
          module={vod.charAt(0).toUpperCase()}
          forPersona={PERMISSIONS.vodTester}
        />}/>
        <Route path="/vod-track-and-trace"  element={<RouteWithAuth
          component={WithMasterLayout(VODTrackAndTrace, vod)}
          module={vod.charAt(0).toUpperCase()}
          forPersona={PERMISSIONS.vodTester}
        />}/>
        <Route path="/vod-approval-registration/:url_registration_id?/:url_mode?"  element={<RouteWithAuth
          component={WithMasterLayout(VODApprovalRegistration, vod)}
          module={vod.charAt(0).toUpperCase()}
          forPersona={PERMISSIONS.vodAdmin}
        />}/>
        <Route path="/vod-seller-booking"  element={<RouteWithAuth
          component={WithMasterLayout(VODSellerBookings, vod)}
          module={vod.charAt(0).toUpperCase()}
          forPersona={PERMISSIONS.vodTester}
        />}/>
        <Route path="/location-management"  element={<RouteWithAuth
          component={WithMasterLayout(VODLocationManagement, vod)}
          module={vod.charAt(0).toUpperCase()}
          forPersona={PERMISSIONS.vodTester}
        />}/>
        <Route path="/system-alert" element={<RouteWithAuth
          component={WithMasterLayout(SystemAlertPage, fms)}
          module={fms.charAt(0).toUpperCase() + fms.slice(1)}
          forPersona={PERMISSIONS.systemAlertView}
        />}/>
        <Route path="*"  element={WithMasterLayout(NotFoundPage, demand)} />
        <Route path="/redirect/:type"  element={<RedirectPage/>} />
      </Routes>
      <Outlet/>
    </>
  );
};

export default AppRoutes;